import defaultTheme from '@bluheadless/ui/src/theme/default'

const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 0,
				minWidth: 220,
				marginTop: 11,
				padding: '5px 25px',
				borderColor: 'var(--color-black)',
				[defaultTheme.breakpoints.up('md')]: {
					marginTop: 15,
				},
			},
			paper: {
				boxShadow: 'none',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				padding: '16px 0',
				'&:not(.Mui-disabled)': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,

					'&:hover': {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
					},
				},
				'&:last-of-type': {
					borderBottom: 0,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
				'&.Mui-selected': {
					backgroundColor: 'transparent',
					color: 'var(--color-black)',
					fontWeight: 600,
					'&:hover, &.Mui-focusVisible': {
						backgroundColor: 'transparent',
					},
				},
				'&.Mui-focusVisible': {
					backgroundColor: 'transparent',
					color: 'var(--color-black)',
				},
				'&:hover': {
					backgroundColor: 'transparent',
					fontWeight: 600,
					color: 'var(--color-black)',
				},
			},
		},
	},
}

export default List
