const BHSearch = {
	BHSearch: {
		defaultProps: {
			priceProps: {
				showPercentage: false,
			},
			wishListProps: {
				position: 'inner',
			},
		},
	},
}

export default BHSearch
