import { alpha } from '@mui/system'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const DatePicker = {
	MuiCalendarPicker: {
		styleOverrides: {
			root: {
				maxHeight: 500,
				maxWidth: 310,
				width: '100%',
				'& > div': {
					'&:first-of-type': {
						position: 'relative',
						padding: defaultTheme.spacing(3, '2px', '23px', '14px'),
						margin: 0,
						minHeight: 71,
						'&:after': {
							position: 'absolute',
							left: 0,
							bottom: 0,
							content: '""',
							width: '100%',
							height: 1,
							backgroundColor: defaultTheme.palette.grey.light,
						},
						'& div[role="presentation"]': {
							fontWeight: defaultTheme.typography.fontWeightBold,
							textTransform: 'uppercase',
						},
						'& > div:last-of-type': {
							'& .Mui-disabled': {
								backgroundColor: 'unset',
								color: alpha('#000000', '0.2'),
							},
							'& div': {
								width: 0,
							},
						},
						[defaultTheme.breakpoints.up('xsm')]: {
							padding: defaultTheme.spacing(3, '2px', '23px', 3),
						},
					},
					'& .MuiIconButton-root': {
						color: defaultTheme.palette.grey.medium,
						marginRight: '-12px',
						[defaultTheme.breakpoints.up('xsm')]: {
							padding: '22px',
							marginRight: 0,
						},
					},
				},
				'& .MuiPickersCalendarHeader-labelContainer': {
					overflow: 'visible',
					'&::after': {
						content: '""',
						display: 'block',
						marginTop: '-6px',
						border: 'solid black',
						borderWidth: '0 2px 2px 0',
						padding: '3px',
						transform: 'rotate(45deg)',
						marginLeft: '3px',
					},
				},
				'& .MuiCalendarPicker-viewTransitionContainer': {
					...defaultTheme.scrollbarJsx,
					'& > div > div:first-of-type': {
						margin: '26px 0 12px',
					},
					'& span': {
						color: 'var(--color-black)',
						width: 36,
						fontSize: defaultTheme.typography.pxToRem(12),
						lineHeight: defaultTheme.typography.pxToRem(14),
						[defaultTheme.breakpoints.up('xsm')]: {
							width: 40,
						},
					},
				},
				'& .MuiPickersDay': {
					'&-root': {
						color: defaultTheme.palette.grey.main,
						borderRadius: defaultTheme.shape.borderRadius + 1,
						width: 36,
						height: 36,
						fontSize: defaultTheme.typography.pxToRem(12),
						lineHeight: defaultTheme.typography.pxToRem(14),
						'&.Mui-selected': {
							...defaultTheme.typography.button,
							backgroundColor: 'var(--color-black)',
							color: 'var(--color-white)',
							borderRadius: '50%',
						},
						'&:hover': {
							backgroundColor: alpha(defaultTheme.palette.grey.light, '0.3'),
						},
						[defaultTheme.breakpoints.up('xsm')]: {
							width: 40,
							height: 40,
						},
					},
					'&-today:not(.Mui-selected)': {
						...defaultTheme.typography.button,
						color: 'var(--color-black)',
						borderColor: defaultTheme.palette.grey.main,
					},
				},
			},
		},
	},
	MuiClockPicker: {
		styleOverrides: {
			arrowSwitcher: {
				display: 'none',
				'& + div': {
					'> div': {
						backgroundColor: alpha(defaultTheme.palette.grey.light, '0.3'),
						'& span': {
							color: 'var(--color-black)',
							width: 36,
							fontSize: defaultTheme.typography.pxToRem(12),
							lineHeight: defaultTheme.typography.pxToRem(14),
							[defaultTheme.breakpoints.up('xsm')]: {
								width: 40,
							},
						},
					},
				},
			},
		},
	},
	MuiYearPicker: {
		styleOverrides: {
			root: {
				'& .PrivatePickersYear-root': {
					'& .PrivatePickersYear-yearButton': {
						width: 70,
					},
				},
			},
		},
	},
}

export default DatePicker
