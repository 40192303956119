/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19 5.9l-.9-.9-6.1 6.1L5.9 5l-.9.9 6.1 6.1L5 18.1l.9.9 6.1-6.1 6.1 6.1.9-.9-6.1-6.1z" />
	</svg>
))
XIconSvg.displayName = 'XIconSvg'

const XIcon = forwardRef((props, ref) => <Icon component={XIconSvg} ref={ref} {...props} />)
XIcon.displayName = 'XIcon'

XIcon.defaultProps = {
	...Icon.defaultProps,
}
XIcon.propTypes = {
	...Icon.propTypes,
}

export default XIcon
export { XIconSvg }
