const SvgIcon = {
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				fontSize: '1.7rem',
			},
			fontSizeExtraSmall: {
				fontSize: '1.2rem',
			},
			fontSizeSmall: {
				fontSize: '16px',
			},
			fontSizeMiddle: {
				fontSize: '1.5rem',
			},
			fontSizeMedium: {
				fontSize: '1.7rem',
			},
			fontSizeButton: {
				fontSize: '22px',
			},
			colorPrimary: {
				color: 'var(--color-black)',
			},
			colorSecondary: {
				color: 'var(--color-white)',
			},
		},
	},
}

export default SvgIcon
