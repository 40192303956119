import defaultTheme from '@bluheadless/ui/src/theme/default'

const Table = {
	MuiTableCell: {
		styleOverrides: {
			root: {
				fontSize: '0.8em',
				[defaultTheme.breakpoints.up('md')]: {
					fontSize: '1em',
				},
			},
		},
	},
}

export default Table
