const Input = {
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&.MuiInputBase-adornedStart': {
					paddingLeft: 0,
				},
				'&.MuiInputBase-adornedEnd': {
					paddingRight: 0,
				},
				'&.MuiAutocomplete-inputRoot': {
					'.MuiSvgIcon-root': {
						fontSize: '17px',
					},
				},
			},
			adornedEnd: {
				paddingRight: 0,
			},
		},
	},
	MuiInput: {
		styleOverrides: {
			input: {
				fontSize: '12px',
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			input: {
				padding: '5px 0',
				fontSize: '12px',
			},
			notchedOutline: {
				border: 0,
				borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
				'&:hover': {
					borderBottom: '2px solid rgb(0, 0, 0)',
				},
			},
			root: {
				'& .MuiAutocomplete-input': {
					padding: '5px 0px',
				},
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				color: 'var(--color-grey)',
				fontSize: '12px',
				marginBottom: 0,
				letterSpacing: '0.55px',
				lineHeight: 1.2,
				textAlign: 'left',
				zIndex: 0,
				position: 'absolute',
				left: 0,
				top: 0,
				transform: 'translate(0px, 18px) scale(1)',
				transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1)',
			},
			shrink: {
				transform: 'translate(0px, 5px) scale(0.8)',
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			hasPopupIcon: {
				'&.MuiAutocomplete-root .MuiOutlinedInput-root': {
					paddingRight: 0,
					'&.MuiInputBase-adornedEnd': {
						paddingRight: 0,
					},
				},
			},
			hasClearIcon: {
				'.MuiInputBase-root.MuiAutocomplete-inputRoot': {
					paddingRight: 0,
				},
			},
			root: {
				'.MuiInput-root .MuiInput-input': {
					paddingLeft: '8px',
				},
			},
		},
	},
	MuiInputAdornment: {
		styleOverrides: {
			root: {
				'.MuiIconButton-root': {
					color: 'var(--color-black)',
				},
			},
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: {
				color: 'var(--color-black)',
			},
			colorSecondary: {
				backgroundColor: 'none',
				color: 'var(--color-white)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'none',
					color: 'var(--color-white)',
					borderColor: 'none',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'none',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'none',
				},
			},
			edgeEnd: {
				padding: 0,
				marginRight: 0,
			},
		},
	},
}
export default Input
