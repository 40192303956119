const BHSuggestedStoreRow = {
	BHSuggestedStoreRow: {
		defaultProps: {
			labelProps: {
				color: 'primary.main',
			},
			buttonSelectProps: {
				color: 'primary',
				variant: 'contained',
			},
			buttonGoProps: {
				color: 'primary',
			},
			buttonCloseProps: {
				variant: 'icon',
				iconProps: { fontSize: 'medium', color: 'black' },
			},
		},
	},
}

export default BHSuggestedStoreRow
