const BHTileBase = {
	BHTileBase: {
		defaultProps: {
			wishListProps: {
				position: 'inner',
				variant: 'icon',
				iconProps: {
					fontSize: 'large',
				},
			},
			addToCartProps: {
				position: 'inner',
				variant: 'icon',
				iconProps: {
					fontSize: 'large',
					color: 'primary',
				},
			},
			title1Props: {
				component: 'h2',
			},
			title2Props: {
				component: 'div',
			},
		},
	},
}

export default BHTileBase
