import theme from '../theme'
import BluHeadlessApp from '@bluheadless/ui-logic/src/app'
import { useEventQueue } from '@bluheadless/ui-logic/src/core-web-vitals/event-queue'
import createEmotionCache from '../create-emotion-cache' // Can't be moved to @bluheadless
import { GlobalStyles } from '@mui/material'
import { css } from '@mui/system'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const ProjectApp = ({ emotionCache = clientSideEmotionCache, ...props }) => {
	return (
		<>
			<GlobalStyles
				styles={css`
					@font-face {
						font-family: 'Gothic';
						src: url('/fonts/Gothic.woff2') format('woff2');
						font-weight: 400;
						font-style: normal;
					}
					@font-face {
						font-family: 'Gothic';
						src: url('/fonts/GothicB.woff2') format('woff2');
						font-weight: 700;
						font-style: normal;
					}
				`}
			/>
			<BluHeadlessApp emotionCache={emotionCache} theme={theme} {...props} />
		</>
	)
}

// Here we can customize global server-side props
ProjectApp.getInitialProps = async (appContext) => {
	const initialProps = await BluHeadlessApp.getInitialProps(appContext)

	return {
		...initialProps,
		pageProps: {
			...initialProps.pageProps,
		},
	}
}

export function reportWebVitals(metric) {
	useEventQueue.getState().add(metric)
}

export default ProjectApp
