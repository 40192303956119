const Link = {
	MuiLink: {
		styleOverrides: {
			root: {
				textDecorationColor: 'var(--color-black)',
			},
			outlinedPrimary: {
				padding: '10px',
				border: '1px solid var(--color-black)',
				color: 'var(--color-black)',
				display: 'inline-block',
				width: '100%',
				maxWidth: '176px',
				textAlign: 'center',
				textDecoration: 'none',
				fontWeight: '600',
				backgroundColor: 'var(--color-white)',
				transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
				'&:hover': {
					backgroundColor: 'var(--color-black)',
					color: 'var(--color-white)',
				},
			},
			outlinedSecondary: {
				padding: '10px',
				border: '1px solid var(--color-white)',
				color: 'var(--color-white)',
				display: 'inline-block',
				width: '100%',
				maxWidth: '176px',
				textAlign: 'center',
				textDecoration: 'none',
				fontWeight: '600',
				backgroundColor: 'var(--color-black)',
				transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
				'&:hover': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-black)',
				},
			},
		},
	},
}

export default Link
