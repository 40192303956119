import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				...fonts.gothic,
				'--layout-max-width': '1440px',
				'--layout-line-height-small': '15px',
				'--layout-line-height-18': '18px',
				'--layout-line-height-medium': '22px',
				'--layout-line-height-big': '26px',
				'--hero-gradient':
					'transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box',
				'--hero-gradient-opacity': '0.7',
				'--color-white': '#ffffff',
				'--color-black': '#000000',
				'--color-red': '#D00A26',
				'--color-grey': '#676767',
				'--color-grey-icon': '#B1B1B1',
			},
			body: {
				...fonts.gothic,
				'& .text-white': {
					color: 'var(--color-white)',
				},
				'& .text-black': {
					color: 'var(--color-black)',
				},
				'& .MuiLink-outlinedPrimary': {
					padding: '13px',
					border: '1px solid var(--color-black)',
					color: 'var(--color-black)',
					display: 'inline-block',
					width: '100%',
					maxWidth: '176px',
					textAlign: 'center',
					textDecoration: 'none',
					fontWeight: '600',
					backgroundColor: 'var(--color-white)',
					transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
					'&:hover': {
						backgroundColor: 'var(--color-black)',
						color: 'var(--color-white)',
					},
				},
				'& .MuiLink-outlinedSecondary': {
					padding: '13px',
					border: '1px solid var(--color-white)',
					color: 'var(--color-white)',
					display: 'inline-block',
					width: '100%',
					maxWidth: '176px',
					textAlign: 'center',
					textDecoration: 'none',
					fontWeight: '600',
					backgroundColor: 'var(--color-black)',
					transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
					'&:hover': {
						backgroundColor: 'var(--color-white)',
						color: 'var(--color-black)',
					},
				},
			},
		},
	},
}

export default CssBaseline
