const BHInput = {
	BHInput: {
		defaultProps: {
			variant: 'standard',
			InputLabelProps: null,
		},
	},
	MuiTextField: {
		defaultProps: {
			variant: 'standard',
			InputLabelProps: null,
		},
	},
}

export default BHInput
