/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21.7 20.8l-4.2-4.2c2.9-3.4 2.7-8.6-.5-11.8-1.7-1.7-3.9-2.5-6.1-2.5s-4.4.8-6.1 2.5c-3.4 3.4-3.4 8.8 0 12.2 1.7 1.7 3.9 2.5 6.1 2.5 2 0 4.1-.6 5.7-2l4.2 4.2.9-.9zm-16-4.7c-1.4-1.4-2.2-3.2-2.2-5.2s.8-3.8 2.2-5.2S9 3.6 10.9 3.6c2 0 3.8.8 5.2 2.2S18.3 9 18.3 11s-.8 3.8-2.2 5.2c-1.4 1.4-3.2 2.2-5.2 2.2s-3.8-.9-5.2-2.3z" />
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
