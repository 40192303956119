import defaultTheme from '@bluheadless/ui/src/theme/default'

const Dialog = {
	MuiDialog: {
		styleOverrides: {
			paperWidthXl: {
				maxWidth: '90vw',
				padding: '118px 140px',
				[defaultTheme.breakpoints.up('md')]: {
					minHeight: 'fit-content',
				},
			},
			paperWidthLg: {
				maxWidth: '944px',
				padding: '118px 140px',
				[defaultTheme.breakpoints.up('md')]: {
					minHeight: 'fit-content',
				},
			},
			paperWidthMd: {
				[defaultTheme.breakpoints.up('md')]: {
					minHeight: 'max-content',
				},
			},
		},
	},
}

export default Dialog
