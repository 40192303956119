/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		id="prefix__Livello_1"
		xmlns="http://www.w3.org/2000/svg"
		x={0}
		y={0}
		viewBox="0 0 24 24"
		xmlSpace="preserve"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<style>{'.prefix__st0{fill:#1d1d1b}'}</style>
		<path
			className="prefix__st0"
			d="M19.2 17.4v-6.9c0-3.7-2.9-6.8-6.5-7.1v-2h-1.2v2C7.8 3.7 5 6.8 5 10.5v6.9H2v1.2h20.3v-1.2h-3.1zm-13.1 0v-6.9c0-3.3 2.7-5.9 5.9-5.9s5.9 2.7 5.9 5.9v6.9H6.1zM12 22.7c1.4 0 2.5-1.1 2.5-2.5h-5c0 1.4 1.1 2.5 2.5 2.5z"
		/>
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
