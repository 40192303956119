const Checkbox = {
	MuiCheckbox: {
		styleOverrides: {
			root: {
				fontSize: '1rem',
				padding: 0,
				marginRight: '8px',
				color: 'var(--color-black)',
			},
			colorPrimary: {
				color: 'var(--color-black)',
			},
		},
	},
}

export default Checkbox
