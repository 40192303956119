/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const ArrowheadDownIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M18.3 7.9L12 14.3 5.7 7.9l-.9.9 7.2 7.3 7.2-7.3z" />
	</svg>
))
ArrowheadDownIconSvg.displayName = 'ArrowheadDownIconSvg'

const ArrowheadDownIcon = forwardRef((props, ref) => <Icon component={ArrowheadDownIconSvg} ref={ref} {...props} />)
ArrowheadDownIcon.displayName = 'ArrowheadDownIcon'

ArrowheadDownIcon.defaultProps = {
	...Icon.defaultProps,
}
ArrowheadDownIcon.propTypes = {
	...Icon.propTypes,
}

export default ArrowheadDownIcon
export { ArrowheadDownIconSvg }
