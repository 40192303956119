const BHBreadcrumbs = {
	BHBreadcrumbs: {
		defaultProps: {
			separator: '|',
			color: 'primary.main',
			linkProps: {
				color: 'primary.main',
			},
			activeLinkProps: {
				underline: 'none',
				color: 'primary.main',
			},
		},
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			separator: {
				margin: '0px 4px',
			},
		},
	},
}

export default BHBreadcrumbs
