import defaultTheme from '@bluheadless/ui/src/theme/default'

const Accordion = {
	MuiAccordionSummary: {
		styleOverrides: {
			expandIconWrapper: {
				color: defaultTheme.palette.primary.main,
				'&.Mui-expanded': {
					color: defaultTheme.palette.primary.main,
				},
			},
		},
	},
}

export default Accordion
