const BHHeader = {
	BHHeader: {
		defaultProps: {
			changeCountryLanguageProps: {
				showFlag: false,
				showSeparator: false,
				showCurrencySymbol: false,
				showArrowDown: false,
			},
		},
	},
}

export default BHHeader
