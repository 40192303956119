const Alert = {
	MuiAlert: {
		styleOverrides: {
			message: {
				padding: '8px 0',
			},
		},
	},
}

export default Alert
