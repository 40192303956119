const BHRecentlyViewed = {
	BHRecentlyViewed: {
		defaultProps: {
			sliderProps: {
				breakpoints: {
					xs: { spaceBetween: 10 },
					md: {
						spaceBetween: 16,
					},
				},
				spaceBetween: 10,
			},
		},
	},
}

export default BHRecentlyViewed
