const BHQuickBuy = {
	BHQuickBuy: {
		defaultProps: {
			PaperProps: {
				sx: {
					padding: 0,
				},
			},
		},
	},
}
export default BHQuickBuy
